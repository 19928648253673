import parser from 'html-react-parser';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import HtmlHolder from '../HtmlHolder';

interface Props {
    name: string;
    data?: any;
}

const renderTwig = ({ name, data, ...props }: Props) => (
    <HtmlHolder html={`{{ pimcore_wysiwyg("${name}", ${JSON.stringify(props)}) }}`} />
);

const PimcoreWysiwyg = ({ ...props }: Props) => {
    if (process.env.RENDER_MODE === 'twig') {
        return renderTwig(props);
    }

    return (
        <Fragment>
            {parser(props.data, {
                replace: (domNode) => {
                    if (domNode.name === 'a') {
                        if (domNode.attribs) {
                            //                            if (domNode.attribs.href.startsWith('http')) {
                            if (
                                domNode.attribs.href.startsWith('http') ||
                                domNode.attribs.href.startsWith('tel:') ||
                                domNode.attribs.href.startsWith('mailto:')
                            ) {
                                return (
                                    <a
                                        href={domNode.attribs.href}
                                        title={domNode.attribs.title}
                                        className={domNode.attribs.class}
                                    >
                                        {domNode.children!.map((c) => c.data)}
                                    </a>
                                );
                            }
                            return (
                                <Link
                                    to={domNode.attribs.href}
                                    title={domNode.attribs.title}
                                    className={domNode.attribs.class}
                                >
                                    {domNode.children!.map((c) => c.data)}
                                </Link>
                            );
                        }
                    }
                },
            })}
        </Fragment>
    );
};

export default PimcoreWysiwyg;
