import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '../../store/app/types';
import useStores from '../../store/use-stores';

const Home = observer(() => {
    const { t } = useTranslation();
    const { AppStore, PageStore } = useStores();
    const { path } = useParams();

    useEffect(() => {
        PageStore.fetchPage("");
    }, [path, PageStore]);

    const handleLocaleChange = useCallback(
        (e: React.FormEvent<HTMLButtonElement>) => {
            AppStore.setLocale(e.currentTarget.value as Locale);
        },
        [AppStore]
    );
    AppStore.setShowContent(false);
    if (!PageStore.isFetching && !PageStore.hasError) {
        return (
            <>
                <Helmet
                    title={PageStore.title}
                    meta={[{name: 'description', content: PageStore.description}]}
                />
            </>
        );
    }

    return null;
});

export default Home;
